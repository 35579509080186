<!-- eslint-disable no-irregular-whitespace -->
<i18n lang="yaml">
ja:
  title_about: ABOUT
  title_access: 来場方法
  title_world: ワールド
  title_catalog: カタログ
  title_event: イベント
  title_real: リアルイベント
  title_contact: お問い合わせ
  about_about: バーチャルマーケットについて
  about_company: 企業ブース出展の過去事例
  about_media: メディア取材について
  about_credit: クレジット
  event:
    official: 公式配信
    streaming: 公認配信/アンバサダー
    event: バーチャル会場イベント
    project: キャンペーン
    visit: 出展者来場告知
    plans: 公式グッズショップ
  logo_title: バーチャルマーケット2024 Winter
  share_text: 【バーチャルマーケット2024 Winter】バーチャル空間最大の祭典に参加しよう！開催期間：2024年12月7日～2024年12月22日
en:
  title_about: ABOUT
  title_access: ACCESS
  title_world: WORLD
  title_catalog: CATALOG
  title_event: EVENT
  title_real: REAL
  title_contact: CONTACT
  about_about: About Virtual Market
  about_company: Past Cases of Company Exhibition
  about_media: Media Coverage
  about_credit: Credits
  event:
    official: Official Stream
    streaming: Collab Stream / Ambassadors
    event: Participatory Event
    project: Promos
    visit: Visit Announcement
    plans: Official Goods Shop
  logo_title: Virtual Market 2024 Winter
  share_text: "【Virtual Market 2024 Winter】Join the world's largest VR festival! Event Period: December 7 - December 22, 2024"
</i18n>
<!-- eslint-enable no-irregular-whitespace -->
<template>
  <div class="hamburger-container">
    <ul class="outer-list">
      <li class="item">
        <HaLink to="/about" class="title">{{ $t('about_about') }}</HaLink>
      </li>
      <li class="item" @click="emit('close-menu')">
        <HaLink to="/access" class="title">{{ $t('title_access') }}</HaLink>
      </li>
      <li class="item" @click="emit('close-menu')">
        <HaLink force-anchor-link to="/2024Winter/world" class="title">{{
          $t(isStartCatalog() ? 'title_catalog' : 'title_world')
        }}</HaLink>
      </li>

      <li v-if="isStartUc" class="item">
        <HaLink force-anchor-link to="/2024Winter/uc/event" class="title">{{
          $t('title_event')
        }}</HaLink>
      </li>

      <li v-if="isRealAvailable" class="item" @click="emit('close-menu')">
        <HaLink force-anchor-link to="/2024Winter/real" class="title">{{
          $t('title_real')
        }}</HaLink>
      </li>

      <li class="item" @click="emit('close-menu')">
        <HaLink force-anchor-link to="/2024Winter/contact" class="title">{{
          $t('title_contact')
        }}</HaLink>
        <ul class="inner-list">
          <li class="item" @click="emit('close-menu')">
            <HaLink to="/media" class="link">{{ $t('about_media') }}</HaLink>
          </li>
        </ul>
      </li>

      <!-- <template v-if="isStartEvent()">
        <li class="item" @click="emit('close-menu')">
          <HaLink force-anchor-link to="/2024Winter/credit" class="title">{{
            $t('about_credit')
          }}</HaLink>
        </li>
      </template> -->
    </ul>
    <div class="share-heading">Share</div>
    <ul class="share-list">
      <li class="item -twitter">
        <HmSocialShareLink class="link" name="twitter" :text="$t('share_text')">
          <HaImage :src="IconTwitter" alt="X(Twitter)" class="image"
        /></HmSocialShareLink>
      </li>
      <li class="item -facebook">
        <HmSocialShareLink
          class="link"
          name="facebook"
          :text="$t('share_text')"
        >
          <HaImage :src="IconFacebook" alt="Facebook" class="image"
        /></HmSocialShareLink>
      </li>
    </ul>
    <div class="lang-heading">Language:</div>
    <div class="lang-wrap">
      <label
        :class="['link', { '-active': i18n.locale.value === 'ja' }]"
        @click="setLocale('ja')"
      >
        <IconLangJp class="icon" />
      </label>
      <label
        :class="['link', { '-active': i18n.locale.value === 'en' }]"
        @click="setLocale('en')"
      >
        <IconLangEn class="icon" />
      </label>
    </div>
    <HaLink force-anchor-link to="/" class="menu-logo">
      <HaImage :src="EventLogo" :alt="$t('logo_title')" class="image" />
    </HaLink>
  </div>
</template>
<script lang="ts" setup>
import { DeepReadonly } from 'vue'
import HaImage from '@/components/ha/HaImage.vue'
import IconLangJp from '@/assets/icons/icon-lang_jp.svg?component'
import IconLangEn from '@/assets/icons/icon-lang_en.svg?component'
import EventLogo from '@/assets/images/logo-2024winter.webp?url'
import IconTwitter from '@/assets/icons/x_icon_w.svg?url'
import IconFacebook from '@/assets/icons/icon-facebook.svg?url'

const emit = defineEmits<{
  (event: 'close-menu'): void
  (event: 'set-locale', locale: string): void
}>()

// const { showCompanyPage } = useSearchCatalog()
const i18n = useI18n()
// const eventPhase = useEventPhase()
const $t = i18n.t

const setLocale = (locale: string) => {
  emit('set-locale', locale)
  emit('close-menu')
}

// 表示切替用フラグ
const isStartUc = false
const isRealAvailable = false
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.hamburger-container {
  background-color: v.$white;
  height: 100%;
  overflow-y: auto;
  padding: v.$space-base v.$space-small 80px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s;
  width: 80%;
}

.outer-list {
  > .item {
    border-bottom: 1px solid v.$gray-8;
    margin-bottom: v.$space-base;
    padding: 0 v.$space-small;

    > .title {
      color: v.$black;
      display: block;
      font-weight: 700;
      padding-bottom: v.$space-base;
    }
  }
}

.inner-list {
  font-size: 14px;
  padding-left: v.$space-large;

  > .item {
    padding-bottom: v.$space-base;

    > .link {
      color: v.$black;

      &.-disabled {
        color: v.$gray-1;
        pointer-events: none;
      }
    }
  }
}

.lang-heading {
  color: v.$white;
  padding: v.$space-normal 0 v.$space-base;
  text-align: center;
}

.lang-wrap {
  display: flex;
  gap: v.$space-normal;
  justify-content: center;

  > .link {
    display: block;
    height: 32px;
    width: 32px;

    &.-active {
      pointer-events: none;

      &:deep(.icon-lang_bg) {
        fill: v.$v24w-primary-01;
      }

      &:deep(.icon-lang_inner) {
        fill: v.$white;
      }
    }

    &:deep(.icon-lang_inner) {
      fill: v.$v24w-primary-01;
    }

    &:deep(.icon-lang_bd) {
      fill: v.$white;
    }

    &:deep(.icon-lang_bg) {
      fill: v.$white;
      stroke: v.$v24w-primary-01;
    }
  }
}

.share-heading {
  color: v.$white;
  padding: v.$space-normal 0 v.$space-base;
  text-align: center;
}

.share-list {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 50%;

  > .item {
    height: 48px;
    width: 48px;

    > .image {
      height: 100%;
      width: 100%;
    }
  }
}

.menu-logo {
  display: block;
  margin: v.$space-xlarge auto 0;
  text-align: center;
  width: 180px;
}
</style>

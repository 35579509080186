<i18n lang="yaml">
ja:
  mainlogo: バーチャルマーケット2024 Winter
  login: ログイン
  mypage: マイページ

en:
  mainlogo: Virtual Market 2024 Winter
  login: Login
  mypage: Mypage
</i18n>

<template>
  <header :class="['ho-the-header', isTop]">
    <nav class="header-container">
      <HaLink class="header-logo" to="/">
        <HaImage :src="MainLogo" :alt="i18n.t('mainlogo')" class="image" />
      </HaLink>
      <ul class="mainmenu">
        <li
          :class="[
            'mainmenu-item',
            { '-current': currentPage.includes('/about') },
          ]"
        >
          <HaLink to="/about" class="link">
            <span class="textmain">ABOUT</span>
            <span class="textsub">概要</span>
          </HaLink>
        </li>
        <li
          :class="[
            'mainmenu-item',
            { '-current': currentPage.includes('/access') },
          ]"
        >
          <HaLink to="/access" class="link">
            <span class="textmain">ACCESS</span>
            <span class="textsub">来場方法</span>
          </HaLink>
        </li>
        <li class="mainmenu-item">
          <HaLink to="/2024Winter/world" class="link">
            <span class="textmain">{{ worldTextMain }}</span>
            <span class="textsub">{{ worldTextSub }}</span>
          </HaLink>
        </li>
        <li :class="['mainmenu-item', { '-disabled': !isUcAvailable }]">
          <HaLink
            :to="isUcAvailable ? '/2024Winter/uc/event' : undefined"
            class="link -disabled"
          >
            <span class="textmain">EVENT</span>
            <span class="textsub">イベント</span>
          </HaLink>
        </li>
        <li :class="['mainmenu-item', { '-disabled': !isRealAvailable }]">
          <HaLink
            :to="isRealAvailable ? '/2024Winter/real' : undefined"
            class="link -disabled"
          >
            <span class="textmain">REAL</span>
            <span class="textsub">リアルイベント</span>
          </HaLink>
        </li>
        <li class="mainmenu-item">
          <HaLink to="/2024Winter/contact" class="link">
            <span class="textmain">CONTACT</span>
            <span class="textsub">お問い合わせ</span>
          </HaLink>
        </li>
      </ul>
      <ul class="submenu">
        <li class="submenu-item">
          <HaLink class="login-link" to="/2024Winter/mypage">
            <IconUser class="icon" />
            <span class="text">{{ i18n.t('mypage') }}</span>
          </HaLink>
        </li>
        <li class="submenu-item -language">
          <div class="language-button">
            <IconEarth class="icon" />
            <p class="text">Language</p>
          </div>
          <div class="language-lowermenu">
            <label
              :class="['langlink', { '-active': i18n.locale.value === 'ja' }]"
              @click="changeLocale('ja')"
            >
              日本語
            </label>
            <label
              :class="['langlink', { '-active': i18n.locale.value === 'en' }]"
              @click="changeLocale('en')"
            >
              English
            </label>
          </div>
        </li>
      </ul>
      <!-- SP版 -->
      <ul class="sp-header">
        <li class="item">
          <HaLink class="login-link" to="/2024Winter/mypage">
            <IconUser class="icon" />
            <span class="text">{{ i18n.t('mypage') }}</span>
          </HaLink>
        </li>
        <li class="item">
          <button
            type="button"
            :aria-expanded="menuVisibility"
            class="button-hamburger"
            @click="toggleSubMenu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="icon"
            >
              <rect
                x="1.5"
                y="3.6001"
                width="21"
                height="2.4"
                rx="1.2"
                fill="#104097"
                class="line -top"
              />
              <rect
                x="1.5"
                y="10.7998"
                width="21"
                height="2.4"
                rx="1.2"
                fill="#104097"
                class="line -middle"
              />
              <rect
                x="1.5"
                y="18"
                width="21"
                height="2.4"
                rx="1.2"
                fill="#104097"
                class="line -bottom"
              />
            </svg>
          </button>
          <div class="menu-hamburger" :aria-hidden="!menuVisibility">
            <div class="menu-overlay" @click="toggleSubMenu" />
            <HoSpMenu
              @close-menu="toggleSubMenu"
              @set-locale="changeLocale($event)"
            />
          </div>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script lang="ts" setup>
import MainLogo from '@/assets/images/logo-2024winter.webp?url'
import IconEarth from '@/assets/icons/icon-earth.svg'
import IconUser from '@/assets/icons/icon-user.svg'
import { content } from '@/utils/content'

const i18n = useI18n()
const route = useRoute()

const visibleSubMenu = ref<string | null>(null) // TODO: リテラル

const creatorUrl = computed((): string => {
  return content.url.creator
})

const changeLocale = (locale: string) => {
  i18n.setLocale(locale)
}

const menuVisibility = ref<boolean>(false)
const toggleSubMenu = () => {
  menuVisibility.value = !menuVisibility.value
}

// const toggleSubMenu = (menuName: string | null) => {
//   if (!menuName) {
//     visibleSubMenu.value = null
//     return
//   }
//   if (visibleSubMenu.value === 'sp-user' && menuName === 'sp-user') {
//     visibleSubMenu.value = null
//     return
//   }
//   if (menuName === 'hamburger-enabled') {
//     visibleSubMenu.value = menuName
//     setTimeout(() => {
//       visibleSubMenu.value = 'hamburger'
//     }, 100)
//     return
//   }
//   if (menuName === 'hamburger-disabled') {
//     visibleSubMenu.value = menuName
//     setTimeout(() => {
//       visibleSubMenu.value = null
//     }, 100)
//     return
//   }
//   visibleSubMenu.value = menuName
// }

const worldTextMain = computed(() => 'WORLD')
const worldTextSub = computed(() => 'ワールド')

// URLはクライアント側でしかとれないので、process.clientで判定する
const isTop = process.client
  ? new URL(window.location.href).pathname === '/'
    ? '-top'
    : '-sub'
  : '-top'

const currentPage = computed<string>(() => route.path)

// リアルイベントのリンク開けるフラグ ハードコーディング
const isRealAvailable = false
// UCイベントのリンク開けるフラグ ハードコーディング
const isUcAvailable = false
</script>
<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
$background-color: v.$white;
$menu-font-active-color: v.$blue-5;

.ho-the-header {
  background-color: v.$v24w-base-07;
  box-shadow: 0 3px 8px rgba(v.$black, 0.6);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: v.$zindex-header;

  &.-top {
    backdrop-filter: blur(10px);
    background-color: rgba(v.$v24w-base-07, 0.9);
  }
}

.header-container {
  align-items: center;
  display: flex;
  height: v.$header-height-pc;
  justify-content: space-around;
  margin: 0 auto;
  padding-left: v.$space-normal;
  position: relative;
  z-index: v.$zindex-header;
  @include m.tb() {
    height: 48px;
    justify-content: space-between;
    padding-left: v.$space-small;
  }

  > .mainmenu {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    overflow: hidden;
  }

  > .submenu {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  > .sp-header {
    display: none;
  }
  @include m.tb() {
    > .mainmenu,
    > .submenu {
      display: none;
    }

    > .sp-header {
      display: flex;
      gap: v.$space-base;

      > .item {
        align-items: center;
        display: flex;
      }
    }
  }
}

.header-logo {
  display: block;

  > .image {
    width: 120px;
    @include m.tb() {
      height: 32px;
      width: auto;
    }
  }
}

.mainmenu-item {
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  line-height: 24px;
  padding: 0 10px;
  text-align: center;
  transition: border-bottom-color 0.3s ease-out;

  @media (any-hover: hover) {
    &:not(.-disabled):hover {
      border-bottom-color: v.$v24w-primary-01;

      .header-lowermenu {
        animation: show-opacity 0.08s linear 0s;
        display: block;
      }
    }
  }

  &:not(.-disabled).-current {
    border-bottom-color: v.$v24w-primary-01;
  }

  &:not(.-disabled > link:hover) {
    opacity: 1;

    > .textmain,
    > .textsub {
      color: v.$white;
    }
  }

  &.-disabled {
    > .link {
      color: v.$v24w-text-white-01;
      cursor: auto;
    }
  }

  > .link {
    color: v.$v24w-text-white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 10px;

    > .textmain {
      display: block;
      font-size: 22px;
    }

    > .textsub {
      display: block;
      font-size: 12px;
    }
  }
}

.header-lowermenu {
  display: none;
  left: 0;
  position: absolute;
  top: 80px;
  width: 100%;

  &.-world {
    background-color: v.$v24w-base-sub;
  }

  &.-event {
    background-color: v.$v24w-base-08;
  }
}

.submenu-item {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;

  &.-language {
    cursor: pointer;

    @media (any-hover: hover) {
      &:hover {
        .language-lowermenu {
          animation: show-opacity 0.08s linear 0s;
          display: block;
        }
      }
    }
  }
}

.login-link {
  align-items: center;
  background-color: v.$white;
  border: 2px solid v.$v24w-primary-01;
  border-radius: 6px;
  color: v.$blue;
  display: flex;
  font-size: 14px;
  margin-right: v.$space-base;
  padding: v.$space-small v.$space-base;
  transition:
    background-color 0.3s ease-out,
    border-color 0.3s ease-out;
  white-space: nowrap;

  @include m.tb {
    margin-right: 0;
    padding: v.$space-xsmall v.$space-small;
  }

  > .icon {
    border: 2px solid v.$v24w-primary-01;
    border-radius: 13px;
    height: 26px;
    margin-right: v.$space-small;
    object-fit: cover;
    transition: border-color 0.3s ease-out;
    width: 26px;

    &:deep(.a) {
      fill: v.$v24w-primary-01;
      transition: fill 0.3s ease-out;
    }
  }

  > .text {
    color: v.$v24w-text-color-01;
    transition: color 0.3s ease-out;
  }

  @media (any-hover: hover) {
    &:hover {
      background-color: v.$v24w-secondary-02;
      border-color: v.$v24w-secondary-02;

      > .icon {
        border-color: v.$v24w-secondary-02;

        &:deep(.a) {
          fill: v.$v24w-base-white;
        }
      }

      > .text {
        color: v.$v24w-text-white;
      }
    }
  }
}

.language-button {
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  margin-right: v.$space-base;

  > .icon {
    fill: v.$v24w-secondary-03;
    height: 20px;
    margin-right: v.$space-xsmall;
    width: 20px;
  }

  > .text {
    color: v.$v24w-secondary-03;
  }
}

.language-lowermenu {
  background-color: v.$white;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  cursor: default;
  display: none;
  left: 0;
  padding: 8px;
  position: absolute;
  top: 60px;
  width: 110px;

  > .langlink {
    color: v.$v24w-text-black;
    cursor: pointer;
    display: block;
    padding: v.$space-xsmall 0;
    text-align: center;
    transition:
      background-color 0.3s ease-out,
      color 0.3s ease-out;

    &.-active {
      cursor: default;
      font-weight: 600;
      pointer-events: none;
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: v.$gray;
        color: v.$v24w-secondary-02;
      }
    }
  }
}

// SP menu style
.button-user {
  > .icon {
    height: 24px;
    width: 24px;

    > path {
      fill: v.$primary-color;
    }
  }
}

.button-hamburger {
  align-items: center;
  aspect-ratio: 1/1;
  background-color: v.$v24w-base-08;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  width: 48px;

  .line {
    fill: v.$v24w-base-white;
    transform-box: fill-box;

    &.-top {
      transform: rotate(0deg);
      transition: transform 0.3s;
    }

    &.-middle {
      opacity: 1;
      transition: opacity 0.3s;
    }

    &.-bottom {
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
  }

  &[aria-expanded='true'] {
    .line {
      &.-top {
        transform: rotate(45deg) translateX(1px) translateY(-1px);
        transition: transform 0.3s;
      }

      &.-middle {
        opacity: 0;
        transition: opacity 0.3s;
      }

      &.-bottom {
        transform: rotate(-45deg) translateY(-0.5px);
        transition: transform 0.3s;
      }
    }
  }
}

.menu-hamburger {
  height: calc(100vh - 48px);
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 48px;
  transition: opacity 0.3s;
  visibility: hidden;
  width: 100%;

  &[aria-hidden='false'] {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s;
    visibility: visible;

    :deep(.hamburger-container) {
      transform: translateX(0);
      transition: transform 0.3s;
    }
  }
}

.menu-overlay {
  background-color: rgba(v.$v24w-base-08, 0.7);
  height: 100%;
  opacity: 0.8;
  width: 100%;
}

@keyframes show-opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
